import { useLayoutEffect } from 'react'
import { useTranslations } from '../../hooks/use-translations'
import { navigate } from 'gatsby'

const InspectionPresentation = () => {
  const { getPath } = useTranslations()
  useLayoutEffect(() => {
    navigate(getPath('/inspection/'), {
      state: { presentationModalOpened: true },
      replace: true,
    })
  }, [getPath])
  return null
}

export default InspectionPresentation
